import React from 'react';
import InputCopyButton from '../../components/global/InputCopyButton';
import { toFixedTrunc, getCurrencySymbol, getCryptoStatus, getSlicedAddress } from '../../../helpers';
import { ErrorTransactions } from '../../constant';

export default function CryptoReceiveMobileView({ data }) {
  const details = data?.transactionDetails?.[0] || {};

  const renderTransactionGroup = (label, value, copyValue = value) => (
    value && (
      <div className="jncTransactionModal__group">
        <p className="jncTransactionModal__group-text">{label}:</p>
        <div className="jncTransactionModal__group-grid">
          <p className="jncTransactionModal__group-text">{value}</p>
          <InputCopyButton copyValue={copyValue} />
        </div>
      </div>
    )
  );

  return (
    <div>
      {renderTransactionGroup('FromWalletAddress', getSlicedAddress(details?.fromWalletAddress))}
      {renderTransactionGroup('ToWalletAddress', getSlicedAddress(details.toWalletAddress))}
      {renderTransactionGroup('CryptoId', details.crypto)}
      {renderTransactionGroup('CryptoAmount', `${getCurrencySymbol(details.crypto)} ${toFixedTrunc(details.amount, 6)}`)}

      {ErrorTransactions.includes(data?.status?.code) && (
        <div className="jncTransactionModal__group">
          <p className="jncTransactionModal__group-text">{getCryptoStatus(data.status.code)}:</p>
          <div className="jncTransactionModal__group-grid">
            <p className="jncTransactionModal__group-text">{data.status.message}</p>
            <InputCopyButton copyValue={data.status.message} />
          </div>
        </div>
      )}
    </div>
  );
}
