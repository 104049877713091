import React from 'react'
import JunoClientActionModal from '../table/JunoClientActionModal'
import JncButton from '../global/JncButton'
import CloseIcon from '@mui/icons-material/Close';

export const JncFailedModal = ({ open, onClose, error, title }) => {

    const dialogContentComponent = () => {
        return (
            <div className="flex justify-center items-center flex-col validationModal">
                <div className="rounded-full bg-[#FEF3F2] p-3">
                    <div className=" bg-[#FEE4E2] rounded-full p-3 ">
                        <div className="rounded-full flex items-center justify-center">
                            <CloseIcon className='!w-10 !h-10 text-[#D92D20] m-0'/>
                        </div>
                    </div>
                </div>
                <p className="validationModal">{title}</p>
                <p className="validationModal-subtitle">{error}</p>
            </div>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <>
                <div className="jncModal__resp w-full" >
                    <JncButton
                        text="Retry"
                        onClickCall={onClose}
                        className={"w-full"}
                        isOutlinedStyle={true}
                        isDangerStyle={true}
                    />
                </div>
            </>
        )
    }
    return (
        <JunoClientActionModal
            dialogClassName={"jn-addressdetails-delete"}
            onClose={onClose}
            states={{
                start: true,
                confirm: false,
            }}
            open={open}
            dialogContent={dialogContentComponent()}
            dialogActions={dialogActionsComponent()}
        />
    )
}
