import React from 'react';
import CopyButton from '../../components/global/CopyButton';
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers';
import { ErrorTransactions } from '../../constant';
import { CommonCryptoExpanded } from './CommonCryptoExpanded';

export default function ReceiveExpanded({ transactionDetails, transactionFee, balanceAfterTransaction, currencyType, status }) {
    const details = transactionDetails?.[0] || {};

    return (
        <div className="jncExpanded">
            <div className="jncExpanded-grid">
                {CommonCryptoExpanded("CryptoId", details.crypto)}
                {CommonCryptoExpanded("FromWalletAddress", details.fromWalletAddress)}
                {CommonCryptoExpanded("ToWalletAddress", details.toWalletAddress)}
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">CryptoAmount</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>
                            {getCurrencySymbol(details.crypto)}
                            {toFixedTrunc(details.amount, 2) || "-"}
                        </p>
                    </div>
                </div>

                {CommonCryptoExpanded("Transaction Fee", transactionFee)}
                {CommonCryptoExpanded("Total Amount", details.totalAmount)}
                {ErrorTransactions.includes(status?.code) && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-failed">{getStatus(status.code)}:</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{status.message}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
