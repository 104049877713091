import React from 'react';
import CopyButton from '../../components/global/CopyButton';

export const CommonCryptoExpanded = (label, value, copyValue = value) => {
    return (
        value ? (
            <div className="jncExpanded-block">
                <p className="jncExpanded-label">{label}</p>
                <div className="jncExpanded-block-copy jncExpanded-text">
                    <p className="is-ellipsis">{value}</p>
                    <CopyButton copyValue={copyValue} />
                </div>
            </div>
        ) : ""
    );
};
